// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-gallery-src-pages-index-tsx": () => import("./../../../node_modules/gatsby-theme-gallery/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-gallery-src-pages-index-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualno-js": () => import("./../../../src/pages/aktualno.js" /* webpackChunkName: "component---src-pages-aktualno-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-delavnice-js": () => import("./../../../src/pages/delavnice.js" /* webpackChunkName: "component---src-pages-delavnice-js" */),
  "component---src-pages-index-obdrzi-za-pol-js": () => import("./../../../src/pages/indexObdrziZaPol.js" /* webpackChunkName: "component---src-pages-index-obdrzi-za-pol-js" */),
  "component---src-pages-komunikacija-otrok-js": () => import("./../../../src/pages/komunikacija-otrok.js" /* webpackChunkName: "component---src-pages-komunikacija-otrok-js" */),
  "component---src-pages-komunikacija-partner-js": () => import("./../../../src/pages/komunikacija-partner.js" /* webpackChunkName: "component---src-pages-komunikacija-partner-js" */),
  "component---src-pages-komunikacija-prijatelji-js": () => import("./../../../src/pages/komunikacija-prijatelji.js" /* webpackChunkName: "component---src-pages-komunikacija-prijatelji-js" */),
  "component---src-pages-komunikacija-sluzba-js": () => import("./../../../src/pages/komunikacija-sluzba.js" /* webpackChunkName: "component---src-pages-komunikacija-sluzba-js" */),
  "component---src-pages-komunikacija-splosna-js": () => import("./../../../src/pages/komunikacija-splosna.js" /* webpackChunkName: "component---src-pages-komunikacija-splosna-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-omeni-js": () => import("./../../../src/pages/omeni.js" /* webpackChunkName: "component---src-pages-omeni-js" */),
  "component---src-pages-osebna-komunikacija-js": () => import("./../../../src/pages/osebna-komunikacija.js" /* webpackChunkName: "component---src-pages-osebna-komunikacija-js" */),
  "component---src-pages-pomoc-pri-promociji-js": () => import("./../../../src/pages/pomoc-pri-promociji.js" /* webpackChunkName: "component---src-pages-pomoc-pri-promociji-js" */),
  "component---src-pages-poslusalka-js": () => import("./../../../src/pages/poslusalka.js" /* webpackChunkName: "component---src-pages-poslusalka-js" */),
  "component---src-pages-predavanja-js": () => import("./../../../src/pages/predavanja.js" /* webpackChunkName: "component---src-pages-predavanja-js" */),
  "component---src-templates-novica-js": () => import("./../../../src/templates/Novica.js" /* webpackChunkName: "component---src-templates-novica-js" */)
}

