module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Komunikacija je kreacija","short_name":"komunikacija-kreacija","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"static/icons/kom-kr-logo.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"db25f341081af7a4b14209a838435d03"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/mnt/data/komunikacija-kreacija/src/cms/cms.js","manualInit":false,"name":"test"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
